.header {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  background-color: rgb(0, 115, 255);

  &__logo {
    display: block;
    height: 4.2rem;
  }
}
