.header {
  background-color: #0073ff;
  align-items: center;
  display: flex;
}

.header__logo {
  height: 4.2rem;
  display: block;
}

ol {
  padding-inline-start: 0;
}

.sub_item {
  padding-left: 1rem;
}

ol li {
  list-style-type: none;
}

.btn {
  margin: .1rem;
  display: inline-block;
}

.done_btn {
  margin: .1rem .1rem .1rem .5rem;
}

.activity_item {
  width: 80%;
  border-style: solid;
  border-color: #006eff;
  border-radius: 25px;
  margin: .5rem;
  padding: .25rem 2rem;
}

.sub_category {
  bottom: 0;
}

.log_session {
  width: 100%;
  position: relative;
}

.log_session_form {
  background-color: #fff;
  display: none;
}

.notes_form_input {
  height: 2rem;
}

.addCategoryInputDiv, #categorySelectMainViewDiv {
  display: none;
}

#categorySelectMainView {
  appearance: menulist;
}

#assignCategoryBtn, #categoryViewBtnsDiv {
  display: none;
}

#variationSelect {
  margin-bottom: 2rem;
}

.lastWorkout {
  position: absolute;
  right: 2rem;
}

.activity_heading_form, #assignToCategoryBtn, #activityCategorySubtitle {
  display: none;
}

/*# sourceMappingURL=index.dc5179f2.css.map */
