@import 'header';

ol {
  padding-inline-start: 0px;
}

.sub_item {
  padding-left: 1rem;
}

ol li {
  list-style-type: none;
}

.btn {
  display: inline-block;
  margin: 0.1rem;
}

.done_btn {
  margin: 0.1rem 0.1rem 0.1rem 0.5rem;
}

// .activities {
//   margin: 0rem;
// }

.activity_item {
  // display: flex;
  // position: relative;
  padding: 0.25rem 2rem;
  margin: 0.5rem;
  border-radius: 25px;
  width: 80%;
  border-style: solid;
  border-color: rgb(0, 110, 255);
}

.sub_category {
  // position: absolute;
  bottom: 0px;
}

.log_session {
  position: relative;
  width: 100%;
}
.log_session_form {
  display: none;
  // position: fixed;
  // left: 20%;
  // top: 10%;
  // border: 3px solid #999;
  background-color: white;
}

.notes_form_input {
  height: 2rem;
}

.addCategoryInputDiv {
  display: none;
}

#categorySelectMainViewDiv {
  display: none;
}

#categorySelectMainView {
  // appearance did not solve the issue
  appearance: menulist;
}

#assignCategoryBtn {
  display: none;
}

#categoryViewBtnsDiv {
  display: none;
}

#variationSelect {
  margin-bottom: 2rem;
}

.lastWorkout {
  position: absolute;
  right: 2rem;
}

.activity_heading_form {
  display: none;
}

#assignToCategoryBtn {
  display: none;
}

#activityCategorySubtitle {
  display: none;
}

// #showAllActivitiesBtn {
//   display: none;
// }
